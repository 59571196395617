<template>
  <div id="way-main">
    <!-- <el-dialog
      class="special"
      :visible.sync="dialogVisible"
      center
    >
    <a href="https://forms.gle/bZ47ty58hUPHYj4C6" target="_blank">
      <img src="../assets/image/special-banner.png" alt />
    </a>
    </el-dialog> -->
    <div class="bg-black">
      <video autoplay muted loop id="myVideo">
        <source src="../assets/image/video/SMID-intro1.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="content">
      <div class="container centered">
        <el-row :gutter="0">
          <el-col :span="24">
            <img src="../assets/image/logo_main.png" class="logo-main" alt />

            <p class="text-auto">
              <a>ย่านนวัตกรรมการแพทย์</a>
              <a
                href=""
                class="typewrite"
                data-period="2000"
                data-type='[ "สวนดอก ( Suandok Medical Innovation District ) มุ่งสู่การเป็นศูนย์กลางแห่งนวัตกรรมการแพทย์ของภูมิภาคอย่างยั่งยืน"]'
              >
                <span class="wrap"></span>
              </a>
            </p>
          </el-col>
          <el-col :span="24" :sm="24" :md="12">
            <button class="btn-in-side-1" @click="watchVideo = true">
              รับชมวิดีโอแบบเต็ม
            </button>
          </el-col>
          <el-col :span="24" :sm="24" :md="12">
            <button class="btn-in-side" @click="goToPage">
              เข้าสู่เว็บไซต์
            </button>
          </el-col>
        </el-row>
      </div>
    </div>

    <el-dialog
      :visible.sync="watchVideo"
      :fullscreen="true"
      class="text-center watchVideo"
    >
      <iframe
        v-if="watchVideo == true"
        class=""
        src="https://www.youtube.com/embed/4EkVYjuVBgs"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </el-dialog>
  </div>
</template>
<style lang="scss" scope>
.bg-black {
  width: 100%;
  height: 100%;
  background: black;
  position: fixed;
  z-index: 999;
}
.special {
  .el-dialog {
    width: 50% !important;
    @media (max-width: 991.98px) {
      width: 80% !important;
    }
    @media (max-width: 575.98px) {
      width: 90% !important;
    }
  }
  .el-dialog__header {
    padding: 0;
    padding-top: 10px;
    background-color: #ececee;
  }
  .el-dialog__headerbtn {
    top: 10px;
    right: 10px;
    
    background: #b12b30;
    padding: 5px;
    border-radius: 50px;
  }
  .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
  }
  .el-dialog__body {
    background-color: #ececee;
    text-align: center;
    @media (max-width: 575.98px) {
      padding: 40px 15px 20px;
    }
    img {
      width: 100%;
      max-width: 650px;
    }
  }
}
.fade-in {
  animation: fadeIn ease 20s;
  -webkit-animation: fadeIn ease 20s;
  -moz-animation: fadeIn ease 20s;
  -o-animation: fadeIn ease 20s;
  -ms-animation: fadeIn ease 20s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
<script>
export default {
  data() {
    return {
      watchVideo: false,
      // dialogVisible: true,
    };
  },
  mounted() {
    this.textAuto();
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    goToPage() {
      this.$router.push("/home");
      if (this.user && this.user.role == 50) {
        setTimeout(() => {
          document.getElementById("nav-admin").style.display = "block";
        }, 300);
      }
    },
    textAuto() {
      var TxtType = function (el, toRotate, period) {
        this.toRotate = toRotate;
        this.el = el;
        this.loopNum = 0;
        this.period = parseInt(period, 50) || 2000;
        this.txt = "";
        this.tick();
        this.isDeleting = false;
      };

      TxtType.prototype.tick = function () {
        var i = this.loopNum % this.toRotate.length;
        var fullTxt = this.toRotate[i];

        if (this.isDeleting) {
          this.txt = fullTxt.substring(0, this.txt.length - 1);
        } else {
          this.txt = fullTxt.substring(0, this.txt.length + 1);
        }

        this.el.innerHTML = '<span class="wrap">' + this.txt + "</span>";

        var that = this;
        var delta = 100 - Math.random() * 100;

        if (this.isDeleting) {
          delta /= 2;
        }

        if (!this.isDeleting && this.txt === fullTxt) {
          delta = this.period;
          // this.isDeleting = true;
        } else if (this.isDeleting && this.txt === "") {
          this.isDeleting = false;
          this.loopNum++;
          delta = 500;
        }

        setTimeout(function () {
          that.tick();
        }, delta);
      };

      window.onload = function () {
        var elements = document.getElementsByClassName("typewrite");
        for (var i = 0; i < elements.length; i++) {
          var toRotate = elements[i].getAttribute("data-type");
          var period = elements[i].getAttribute("data-period");
          if (toRotate) {
            new TxtType(elements[i], JSON.parse(toRotate), period);
          }
        }
        // INJECT CSS
        var css = document.createElement("style");
        css.type = "text/css";
        css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff}";
        document.body.appendChild(css);
      };
    },
  },
};
</script>